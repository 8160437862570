import TextField from '@/components/TextField'

export default {
  extends: TextField,
  mounted: function () {
    this.field.props = Object.assign({
      mask: '#####',
    }, this.field.props)
  },
}
