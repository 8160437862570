<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
    :section="field.section"
  >
    <v-text-field
      v-model="field.value"
      v-bind="field.props"
      box
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
}
</script>
